// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const sharedEnvironmentConfiguration = {
  authentication: true,
  defaultUserId: null,
  production: false,
  title: 'Digital Foundry',
  clientId: 'f1db489c-5a81-44ab-bc68-05acbdaef0ce',
  tenantId: '0804c951-93a0-405d-80e4-fa87c7551d6a',
  apiUrl: 'http://localhost:5080/api/',
  socketUrl: 'http://localhost:5080/api/ectrhub',
  genericErrorMessage:
    'Unable to connect to App Server. We are having issues with the site. Please contact support',
  roles: ['user'],
  toasterDelay: 3000,
  gTag: {
    Key: 'G-3BHFPH1ZWZ',
    EnableDebugMode: true,
  },
};
export const prodFeatureFlags = {
  featureFlags: [],
  isSplittingScopesFeatureEnabled: false,
  isCtrCalendarizationFeatureEnabled: true,
  isEditCalendarizationFeatureEnabled: true,
  isSearchByProjectStateFeatureEnabled: false,
  isActivityLogFeatureEnabled: false,
};
export const devFeatureFlags = {
  isSplittingScopesFeatureEnabled: true,
  isCtrCalendarizationFeatureEnabled: true,
  isEditCalendarizationFeatureEnabled: true,
  isSearchByProjectStateFeatureEnabled: false,
  isActivityLogFeatureEnabled: true,
};
