<form [formGroup]="formGroup" class="row">
  <div class="col-xs-12 checkbox-wrapper">
    <mat-slide-toggle formControlName="isActive">Active</mat-slide-toggle>
  </div>

  <mat-form-field appearance="outline" class="col-xs-12 col-md-4 col-lg-6">
    <mat-label>Segment</mat-label>
    <mat-select
      formControlName="businessSegmentId"
      matTooltip="Can't edit as this scope is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('businessSegmentId').disabled"
    >
      <mat-option
        *ngFor="let option of businessSegmentsOptions$ | async"
        [value]="option.businessSegmentId"
      >
        {{ option.businessSegmentName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-xs-12 col-md-8 col-lg-6">
    <mat-label>P x D</mat-label>
    <mat-select
      formControlName="pxdId"
      matTooltip="Can't edit as this scope is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('pxdId').disabled"
    >
      <mat-option
        *ngFor="let option of pxdOptions$ | async"
        [value]="option.id"
      >
        {{ option.shortName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-xs-12 col-lg-6">
    <mat-label>Block</mat-label>
    <mat-select
      formControlName="blockId"
      matTooltip="Can't edit as this scope is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('blockId').disabled"
    >
      <mat-option
        *ngFor="let option of blockOptions$ | async"
        [value]="option.blockId"
      >
        {{ option.blockDisplayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="col-xs-12">
    <div
      class="alert alert-warning"
      *ngIf="
        warnWhenMoving &&
        !!formGroup?.get('scopeId').value &&
        !!formGroup?.get('blockId').value &&
        originalBlockId != formGroup?.get('blockId').value
      "
    >
      <mat-icon>info</mat-icon>
      <div>
        Scope will be moved to different block together with all of its
        activities.
      </div>
    </div>
  </div>

  <mat-form-field appearance="outline" class="col-xs-12 col-lg-6">
    <mat-label>Code</mat-label>
    <input
      matInput
      formControlName="code"
      matTooltip="Can't edit as this scope is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('code').disabled"
    />

    <mat-error *ngIf="formGroup.controls.code.errors?.required">
      WBS code is required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-xs-12">
    <mat-label>Name</mat-label>
    <input #input matInput maxlength="150" formControlName="scopeDisplayName" />
    <span matSuffix class="name-hint">
      {{ input.value?.length || 0 }}/150
    </span>

    <mat-error *ngIf="formGroup.controls.scopeDisplayName.errors?.required">
      Name is required
    </mat-error>

    <mat-error
      *ngIf="formGroup.controls.scopeDisplayName.errors?.duplicateName"
    >
      This name is already used by other scope in this block
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-xs-12">
    <mat-label>Description</mat-label>
    <textarea
      appCharacterCounter
      matInput
      formControlName="description"
      maxLength="500"
    ></textarea>
  </mat-form-field>

  <div class="col-xs-12 col-md-6 checkbox-wrapper">
    <mat-slide-toggle formControlName="applyClientCpxFactor"
      >Apply client CPX factor</mat-slide-toggle
    >
  </div>

  <div class="col-xs-12 col-md-6 checkbox-wrapper">
    <mat-slide-toggle formControlName="isDeliverable"
      >Deliverable</mat-slide-toggle
    >
  </div>

  <div class="clearfix"></div>

  <mat-form-field appearance="outline" class="col-xs-12 col-md-6">
    <mat-label>Calc level</mat-label>
    <mat-select formControlName="calcLevel">
      <mat-option
        *ngFor="let calcLevel of calcLevels$ | async"
        [value]="calcLevel"
      >
        {{ calcLevel }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-xs-6 col-md-3">
    <mat-label>CPX factor</mat-label>
    <input
      matInput
      formControlName="cpxFactor"
      type="number"
      min="0"
      max="9.99"
      step="0.01"
    />
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-xs-12 col-md-6">
    <mat-label>Scope value</mat-label>
    <mat-select formControlName="qtyValue">
      <mat-option
        *ngFor="let option of qtyValueOptions$ | async"
        [value]="option.id"
      >
        {{ option.label }}
      </mat-option>
    </mat-select>
    <mat-hint>
      <p>Options are:</p>
      <dl>
        <dt>Quantity</dt>
        <dd>CTR owner can enter own numeric value</dd>
        <dt>Toggle</dt>
        <dd>CTR owner can toggle it on or off</dd>
        <dt>Default</dt>
        <dd>
          Scope will be automatically added to every CTR without possibility to
          remove it
        </dd>
        <dt>Delegation</dt>
        <dd>calculated automatically if there is delegation in CTR</dd>
      </dl>
    </mat-hint>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="col-xs-12 col-md-6"
    *ngIf="unitOptions$ | async as unitOptions"
  >
    <mat-label>Unit</mat-label>
    <input
      matInput
      formControlName="qtyType"
      maxLength="20"
      pattern="[\-a-z0-9 ]+"
      [matAutocomplete]="qtyTypeAutocomplete"
    />
    <mat-autocomplete #qtyTypeAutocomplete="matAutocomplete">
      <mat-option *ngFor="let option of unitOptions" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint>
      <p>
        Unit in which quantity of this scope is defined. Ex: item, x-section,
        day, case, campaign, etc.<br />
        For Standard activities Unit will be inherited from Scope Unit.<br />
        Preferably use standard options. Default is “Item”. Type your own short
        type if needed.<br />
      </p>
      <dl>
        <dt>Weeks</dt>
        <dd>
          quantity will be automatically calculated according to CTR duration
        </dd>
      </dl>
    </mat-hint>
  </mat-form-field>

  <div class="clearfix"></div>

  <mat-form-field
    *ngIf="!formGroup.get('entityId').disabled"
    appearance="outline"
    class="col-xs-12 col-md-6"
  >
    <mat-label>Entity</mat-label>
    <mat-select formControlName="entityId" #entity>
      <mat-option
        *ngFor="let entity of entities$ | async"
        [value]="entity.entityId"
      >
        {{ entity.entityName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="clearfix"></div>
</form>
