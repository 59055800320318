import { ConditionType, SortType } from '@common/enums';
import {
  IFilter,
  IFilterCondition,
  IFilterConditionWithName,
} from '@models/filters';
import { IProjectSearchParams } from '@models/projects';

import {
  convertConditionServerIdListToFormListData,
  convertConditionServerStringListToFormListData,
  convertConditionServerToBooleanFormValue,
  convertFormBooleanToServerData,
  convertFormDateToServerDate,
  convertFormObjectListToServerList,
  convertObjectListToAutocompleteOptionsCallerBound,
  convertServerDateToConditions,
  convertServerDateToFormDate,
  convertServerIdListToFormListData,
  convertServerStringToBooleanFormData,
  convertServerStringToFormData,
  convertStringListToAutocompleteOptions,
  removeConditionItemByValue,
  removeConditionValue,
} from './filters-form-selectors.utils';

/**
 * Keys should match configuration for formControlNames
 * in @see conditionsFormConfiguration
 */
export interface IAdvancedProjectFiltersFormValue {
  requestor: { value: number }[];
  client: { value: number }[];
  projectType: { value: number }[];
  projectStatus: { value: string }[];
  projectStages: { value: number }[];
  entity: { value: number }[];
  scenarioEntity: { value: number }[];
  pxd: { value: number }[];
  dateRange: { createdAfter: Date; createdBefore: Date };
  sortType: SortType;
  saveFilterName: string;
  ctrStatus: { value: string }[];
  ctrDueDate: { value: Date }[];
  userRole: { value: string }[];
  project: { value: number }[];
  ctrOwner: { value: number }[];
  delegatedFrom: { value: number }[];
  delegatedTo: { value: number }[];
  userIsRequestor: { value: boolean };
  userIsSupporter: { value: boolean };
  delegatedToUser: { value: boolean };
  delegatedFromUser: { value: boolean };
}

/**
 * Contains information about how to retrieve data for form field autocompletion
 * and how to transform data from server to form data.
 */
export const filtersByConditionsFormConfiguration: {
  [conditionType in ConditionType]: {
    overviewLabel?: string;
    formFieldLabel: string;
    formControlName: string;
    optionValueKey?: string;
    optionLabelKey?: string;
    allowOtherValues?: boolean;
    convertFormToSeverData: (list: any) => string;
    convertServerToFormData: (
      condition: IFilterCondition,
      context: { [conditionType: number]: { [id: number]: any } },
      allowsOtherValues?: boolean
    ) => any;
    convertServerToFilterOverview?: (
      value: string,
      type: ConditionType,
      context?: { [conditionType: number]: { [id: number]: any } },
      allowOtherValues?: boolean
    ) => IFilterCondition[];
    autocompleteValue?: (list: Object[]) => IFilterConditionWithName[];
    removeReducer: (condition: IFilterCondition, value?: any) => any;
    initialValue?: any;
  };
} = {
  [ConditionType.Requestor]: {
    formFieldLabel: 'Requestor',
    formControlName: 'requestor',
    optionValueKey: 'userId',
    optionLabelKey: 'fullName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.Client]: {
    formFieldLabel: 'Client',
    formControlName: 'client',
    optionValueKey: 'clientId',
    optionLabelKey: 'clientName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.ProjectType]: {
    formFieldLabel: 'Project type',
    formControlName: 'projectType',
    optionValueKey: 'projectTypeId',
    optionLabelKey: 'projectTypeName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.ProjectStatus]: {
    formFieldLabel: 'Project status',
    formControlName: 'projectStatus',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerStringListToFormListData,
    convertServerToFilterOverview: convertServerStringToFormData,
    autocompleteValue: convertStringListToAutocompleteOptions,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.ProjectStage]: {
    formFieldLabel: 'Project stage',
    formControlName: 'projectStage',
    optionValueKey: 'projectStageId',
    optionLabelKey: 'projectStageName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.Entity]: {
    formFieldLabel: 'Entity',
    formControlName: 'entity',
    optionValueKey: 'entityId',
    optionLabelKey: 'entityName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.ScenarioEntity]: {
    formFieldLabel: 'Executing entity of scenario',
    formControlName: 'scenarioEntity',
    optionValueKey: 'entityId',
    optionLabelKey: 'entityName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.PxD]: {
    formFieldLabel: 'Containing CTR',
    formControlName: 'pxd',
    optionValueKey: 'id',
    optionLabelKey: 'shortName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.CreatedAfter]: {
    formFieldLabel: 'Created after',
    formControlName: 'createdAfter',
    convertFormToSeverData: convertFormDateToServerDate,
    convertServerToFormData: convertServerDateToFormDate,
    convertServerToFilterOverview: convertServerDateToConditions,
    removeReducer: removeConditionValue,
  },
  [ConditionType.CreatedBefore]: {
    formFieldLabel: 'Created before',
    formControlName: 'createdBefore',
    convertFormToSeverData: convertFormDateToServerDate,
    convertServerToFormData: convertServerDateToFormDate,
    convertServerToFilterOverview: convertServerDateToConditions,
    removeReducer: removeConditionValue,
  },
  [ConditionType.CtrStatus]: {
    formFieldLabel: 'CTR status',
    formControlName: 'ctrStatus',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerStringListToFormListData,
    convertServerToFilterOverview: convertServerStringToFormData,
    autocompleteValue: convertStringListToAutocompleteOptions,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.CtrOwner]: {
    formFieldLabel: 'CTR Owner',
    formControlName: 'ctrOwner',
    optionValueKey: 'userId',
    optionLabelKey: 'fullName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.Supporter]: {
    formFieldLabel: 'Supporter',
    formControlName: 'supporter',
    optionValueKey: 'userId',
    optionLabelKey: 'fullName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.DelegatedFrom]: {
    formFieldLabel: 'Delegated from',
    formControlName: 'delegatedFrom',
    optionValueKey: 'userId',
    optionLabelKey: 'fullName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.DelegatedTo]: {
    formFieldLabel: 'Delegated to',
    formControlName: 'delegatedTo',
    optionValueKey: 'userId',
    optionLabelKey: 'fullName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.Project]: {
    formFieldLabel: 'Project',
    formControlName: 'project',
    optionValueKey: 'id',
    optionLabelKey: 'projectName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.UserIsOwner]: {
    overviewLabel: 'I am owner',
    formFieldLabel: 'Owner',
    formControlName: 'userIsOwner',
    convertFormToSeverData: convertFormBooleanToServerData,
    convertServerToFormData: convertConditionServerToBooleanFormValue,
    convertServerToFilterOverview: convertServerStringToBooleanFormData,
    removeReducer: removeConditionValue,
  },
  [ConditionType.UserIsSupporter]: {
    overviewLabel: 'I am supporter',
    formFieldLabel: 'Supporter',
    formControlName: 'userIsSupporter',
    convertFormToSeverData: convertFormBooleanToServerData,
    convertServerToFormData: convertConditionServerToBooleanFormValue,
    convertServerToFilterOverview: convertServerStringToBooleanFormData,
    removeReducer: removeConditionValue,
  },
  [ConditionType.DelegatedFromUser]: {
    overviewLabel: 'I have delegated',
    formFieldLabel: 'I delegated',
    formControlName: 'delegatedFromUser',
    convertFormToSeverData: convertFormBooleanToServerData,
    convertServerToFormData: convertConditionServerToBooleanFormValue,
    convertServerToFilterOverview: convertServerStringToBooleanFormData,
    removeReducer: removeConditionValue,
  },
  [ConditionType.DelegatedToUser]: {
    overviewLabel: 'Was delegated to me',
    formFieldLabel: 'Delegated to me',
    formControlName: 'delegatedToUser',
    convertFormToSeverData: convertFormBooleanToServerData,
    convertServerToFormData: convertConditionServerToBooleanFormValue,
    convertServerToFilterOverview: convertServerStringToBooleanFormData,
    removeReducer: removeConditionValue,
  },
  [ConditionType.CtrDueDate]: {
    formFieldLabel: 'CTR due date',
    formControlName: 'ctrDueDate',
    allowOtherValues: true,
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerStringListToFormListData,
    convertServerToFilterOverview: convertServerStringToFormData,
    autocompleteValue: convertStringListToAutocompleteOptions,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.CtrEntity]: {
    formFieldLabel: 'CTR entity',
    formControlName: 'ctrEntity',
    optionValueKey: 'entityId',
    optionLabelKey: 'entityName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
  [ConditionType.SupportingEntity]: {
    formFieldLabel: 'Supporting entity',
    formControlName: 'supportingEntity',
    optionValueKey: 'entityId',
    optionLabelKey: 'entityName',
    convertFormToSeverData: convertFormObjectListToServerList,
    convertServerToFormData: convertConditionServerIdListToFormListData,
    convertServerToFilterOverview: convertServerIdListToFormListData,
    autocompleteValue: convertObjectListToAutocompleteOptionsCallerBound,
    removeReducer: removeConditionItemByValue,
  },
};

export const filtersFormFieldConfiguration = Object.entries(
  filtersByConditionsFormConfiguration
).reduce(
  (r, [type, configuration]) => ({
    ...r,
    [configuration.formControlName]: {
      type: parseInt(type, 10),
      ...configuration,
    },
  }),
  {}
);

export function convertFormValueToFilter(
  formDataValue: IAdvancedProjectFiltersFormValue
): IFilter {
  const conditions = [];

  for (const type in ConditionType) {
    if (filtersByConditionsFormConfiguration[type]) {
      const conditionType = parseInt(type, 10) as ConditionType;
      const { formControlName, convertFormToSeverData } =
        filtersByConditionsFormConfiguration[conditionType];
      const value = formDataValue[formControlName] || null;

      conditions.push({
        type: conditionType,
        value: convertFormToSeverData(value),
      });
    }
  }

  return {
    name: formDataValue.saveFilterName,
    sortType: formDataValue.sortType,
    isFavourite: false,
    conditions: conditions.filter(({ value }) => !!value),
  };
}

export function convertFilterToSearchParamsObject(
  filter: IFilter
): IProjectSearchParams {
  if (!filter) {
    return {};
  }

  const searchParams: IProjectSearchParams = {};

  for (const type in ConditionType) {
    if (filtersByConditionsFormConfiguration[type]) {
      const conditionType = parseInt(type, 10) as ConditionType;
      const { formControlName } =
        filtersByConditionsFormConfiguration[conditionType];
      const value = getFilterConditionByType(filter, conditionType)?.value;
      searchParams[formControlName] = value;
    }
  }

  searchParams.pageNumber = 1;
  searchParams.filterName = filter.name;
  searchParams.sortType = filter.sortType;

  return searchParams;
}

function getFilterConditionByType(filter: IFilter, type: ConditionType) {
  return filter.conditions.find((x) => x.type === type);
}
