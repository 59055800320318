//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { EngineeringRoleType } from '@app/common/enums';

export enum WorkloadDistributionState {
	AutoGenerated = 1,
	Edited = 2,
	NeedsUpdate = 3
}
export enum CTRStatus {
	Selected = 0,
	NotStarted = 1,
	InProgress = 2,
	Completed = 3,
	Cancelled = 4
}
export enum DelegationStatus {
	NoAction = 1,
	Accepted = 2,
	Resolved = 3,
	Rejected = 4,
	Requested = 8,
	RequestedUpdate = 16,
	Pending = 24,
	Unresolved = 28,
	SELECTED_FOR_UPDATE = -2,
	SELECTED = -1
}
export interface ActiveUsersQuery
{
}
export interface InactiveUsersQuery
{
}
export interface ActivitiesExcelQuery
{
	from: string;
	to: string;
	ctrIdList: number[];
	projectIdList: number[];
	businessSegmentId: number;
	includeInProgressCTRs: boolean;
}
export interface ActivityDataDto
{
	calcLevel: string[];
	qtyType: string[];
	qtyVal: { [key:string]: string };
	type: string[];
}
export interface BaseScenarioDto
{
	id: number;
	scenarioNumber: number;
	scenarioName: string;
	scenarioStatus: string;
	ctrDueDate: string;
	submittedDate?: string;
	baseCase: boolean;
	entity: string;
	entityId: number;
	totalCTRs: number;
	completedCTRs: number;
	scenTotHrs: number;
	totalCost: number;
	description: string;
	lastRequestedDate?: string;
	scenarioOwner: string;
}
export interface BlockAssignsDto
{
	blockName: string;
	engHrs: number;
	dftHrs: number;
	scopes: ScopeAssignsDto[];
}
export interface CalendarizationDto
{
	scenarioCTRId: number;
	scenarioId: number;
	entities: string[];
	owner: string;
	startDate: string;
	endDate: string;
	duration: number;
	ctrName: string;
	ctrShortName: string;
	ctrStatus: CTRStatus;
	totalCost: number;
	hoursDistributionForYears: HoursDistributionForMatrix[];
	fteDistributionForYears: FteDistributionForMatrix[];
}
export interface CalendarizationForCtrDto
{
	scenarioCtrId: number;
	startDate: string;
	endDate: string;
	currentUserIsOwner: boolean;
	ctrHoursDistributionForRoles: CtrHoursDistributionForRoles[];
	ctrFteDistributionForRoles: CtrFteDistributionForRoles[];
}
export interface CtrFteDistributionForRoles
{
	fteDistribution: FteDistributionForMatrix[];
	entityId: number;
	entityName: string;
	engineeringRoleId: number;
	roleName: string;
	roleType: string;
	expectedTotalFte: number;
}
export interface CtrHoursDistributionForRoles
{
	hoursDistribution: HoursDistributionForMatrix[];
	entityId: number;
	entityName: string;
	engineeringRoleId: number;
	roleType: string;
	roleName: string;
}
export interface CurrencyDto
{
	currencyCode: string;
	usdfxRate: number;
	lastUpdateDate?: string;
	entityCount: number;
	currencyId: number;
}
export interface EntityForCollaborateDto
{
	id: number;
	name: string;
	supporter: string;
	supporterId: number;
}
export interface FileDto
{
	fileName: string;
	contentType: string;
	stream: string;
}
export interface PrincipalDto
{
	globalEntity: boolean;
	userId: number;
	isEngineer: boolean;
	isRequestor: boolean;
	entityId: number;
	businessSegmentId: number;
	businessSegmentName: string;
	entityName: string;
	fullName: string;
	firstName: string;
	lastName: string;
	email: string;
	isAdmin: boolean;
	isEntityActive: boolean;
	hasCreatedProjects: boolean;
	hasActiveCtrs: boolean;
}
export interface ProjectCurrencyDto
{
	isDefaultCurrency: boolean;
	currencyCode: string;
	fxRate: number;
	id: number;
}
export interface ProjectWithDetailsDto
{
	projectStartDateNotes: string;
	projectEndDateNotes: string;
	fieldLocation: string;
	fieldLocationNotes: string;
	waterDepth: number;
	waterDepthNotes: string;
	maxDesignTemp?: number;
	designTempNotes: string;
	maxDesignPressure?: number;
	designPressureNotes: string;
	enggPhaseDuration?: number;
	enggPhaseDurationNotes: string;
	opPhaseDuration: number;
	opPhaseDurationNotes: string;
	projectCompletionDate?: string;
	hasCompletedCTR: boolean;
	pressureElevation: string;
	scenarios: BaseScenarioDto[];
	id: number;
	clientName: string;
	clientId: number;
	projectName: string;
	projectMDMNumber: string;
	workPackageNumber?: string;
	projectTypeId: number;
	type: string;
	projectStageId: number;
	stage: string;
	projectBidDate?: string;
	projectAwardDate?: string;
	projectStartDate: string;
	projectEndDate: string;
	projectStatus?: string;
	executingEntityId: number;
	requestorName: string;
	requestorId: number;
	currency: string;
	lastUpdateDate?: string;
}
export interface RoleAssignsDto
{
	roleId: number;
	percentage: number;
	value: number;
	type: EngineeringRoleType;
	roleName: string;
}
export interface ScenarioDetailedDto
{
	scenarioDefinitions: any[];
	project: any;
	scenarioCTRs: any[];
	id: number;
	scenarioNumber: number;
	scenarioName: string;
	scenarioStatus: string;
	ctrDueDate: string;
	submittedDate?: string;
	baseCase: boolean;
	entity: string;
	entityId: number;
	totalCTRs: number;
	completedCTRs: number;
	scenTotHrs: number;
	totalCost: number;
	description: string;
	lastRequestedDate?: string;
	scenarioOwner: string;
}
export interface ScenarioWithSCTRDto
{
	scenarioCTRs: any[];
	id: number;
	scenarioNumber: number;
	scenarioName: string;
	scenarioStatus: string;
	ctrDueDate: string;
	submittedDate?: string;
	baseCase: boolean;
	entity: string;
	entityId: number;
	totalCTRs: number;
	completedCTRs: number;
	scenTotHrs: number;
	totalCost: number;
	description: string;
	lastRequestedDate?: string;
	scenarioOwner: string;
}
export interface ScopeAssignsDto
{
	id: number;
	scopeName: string;
	engHrs: number;
	dftHrs: number;
	status: DelegationStatus;
	comment: string;
	entityId: number;
	roles: RoleAssignsDto[];
	canDelegate: boolean;
	acceptOrRejectDate?: string;
	assignedTo: string;
	requestedDate?: string;
	assignedToId: number;
	isInterface: boolean;
	acceptedBySupporter: boolean;
	entityPercentage: number;
}
export interface TimeTableDto
{
	duration?: number;
	startDate?: string;
}
export interface UserDto
{
	userId: number;
	isEngineer: boolean;
	isRequestor: boolean;
	entityId: number;
	businessSegmentId: number;
	businessSegmentName: string;
	entityName: string;
	fullName: string;
	firstName: string;
	lastName: string;
	email: string;
	isAdmin: boolean;
	isEntityActive: boolean;
	hasCreatedProjects: boolean;
	hasActiveCtrs: boolean;
}
export interface UserPxDDto
{
	pxdId: number;
	isPrincipal: boolean;
	pendingCTRs?: boolean;
	entityId: number;
	productId?: number;
	disciplineId?: number;
}
export interface AddSplittedScopeCommand
{
	scenarioCtrId: number;
	scopeEquipsToSplit: ScopeEquipToSplit[];
}
export interface DelegateScopesCommand
{
	sctrId: number;
	delegations: Delegation[];
}
export interface DelegateUsersResponse
{
	userId: number;
	entityId: number;
	fullName: string;
}
export interface Delegation
{
	scopeAssigns: number[];
	entityId: number;
	userId?: number;
}
export interface ScopeEquipToSplit
{
	defaultSctrScopeEquipId: number;
	defaultScopeEquipNewPercentage: number;
	splittedEntities: SplittedEntityData[];
}
export interface SplittedEntityData
{
	entityId: number;
	assignedToId: number;
	entityPercentage: number;
}
export interface UpdateTimeTableCommand
{
	scenarioCTRId: number;
	ctrDuration: number;
	startDate?: string;
}
export interface AdminEntityDto
{
	entityId: number;
	entityName: string;
	currencyCode: string;
	lastUpdateDate?: string;
	currencyId: number;
	isActive: boolean;
	userCount: number;
	projectCount: number;
	ctrCount: number;
	gec: boolean;
	isUsed?: boolean;
}
export interface CalendarizationForCtrQuery
{
	scenarioCtrId: number;
}
export interface CalendarizationQuery
{
	id: number;
}
export interface CompareScenarioCTRResponse
{
	scenarioCTRId: number;
	scenarioId: number;
	status: CTRStatus;
	ctrStatus: string;
	ctrid: number;
	ctrShortName: string;
	productId: number;
	disciplineId: number;
	totalGECHrs: number;
	ctrTotHrs: number;
	ctrTotalCost: number;
	totalGECUtilization: number;
	requestedOn?: string;
}
export interface CompareScenarioResponse
{
	scenarioId: number;
	scenarioName: string;
	baseCase: boolean;
	submittedDate?: string;
	scenarioNumber: number;
	scenarioStatus: string;
	scenarioCTRs: CompareScenarioCTRResponse[];
}
export interface GetWorkLoadForCtrQuery
{
	scenarioCtrId: number;
}
export interface GetWorkLoadForCtrResult
{
	hours: HoursDistributionForMatrix[];
	fte: FteDistributionForMatrix[];
}
export interface ScenariosForComparisonQuery
{
	projectId: number;
}
export interface CancelDelegationCommand
{
	sctrScopeEquipIds: number[];
	scenarioCTRId: number;
}
export interface RequestUpdateOnDelegationCommand
{
	scenarioCTRId: number;
	sctrScopeEquipIds: number[];
}
export interface ResolveDelegationsCommand
{
	scenarioCTRId: number;
	accepted: number[];
	rejected: number[];
}
export interface CompleteCtrCommand
{
	id: number;
}
export interface CopyCtrListQuery
{
	scenarioCTRId: number;
}
export interface HasOtherCtrToCopyCommand
{
	scenarioCTRId: number;
}
export interface SCtrCopyItem
{
	scenarioName: string;
	scenarioNumber: number;
	scenarioStatus: string;
	scenarioSubmittedDate?: string;
	scenarioCTRId: number;
	revisionNumber: number;
	status: CTRStatus;
	ctrStatus: string;
	ctrOwner: string;
	ctrEntity: string;
	ctrCreated?: string;
	ctrManHrs: number;
	ctrCost: number;
	currency: string;
	scenarioId: number;
	projectId: number;
}
export interface SCtrCopyResponse
{
	projectName: string;
	ctRs: SCtrCopyItem[];
}
export interface CreateScenarioCommand
{
	name: string;
	ctrDueDate: string;
	entityId: number;
	projectId: number;
}
export interface CreateScenarioDto
{
	scenarioId: number;
	scenarioNumber: number;
}
export interface ScenarioCtrStartDates
{
	scenarioCtrId: number;
	startDate: string;
}
export interface UpdateStartDateCommand
{
	scenarioId: number;
	editedScenarioCtrStartDates: ScenarioCtrStartDates[];
}
export interface CreateUserCommand
{
	businessSegmentId: number;
	email: string;
	entityId: number;
	firstName: string;
	isActive: boolean;
	isAdmin: boolean;
	isEngineer: boolean;
	isRequestor: boolean;
	lastName: string;
	pxds: UserPxDDto[];
}
export interface CreateUserPublicCommand
{
	businessSegmentId: number;
	email: string;
	entityId: number;
	firstName: string;
	isActive: boolean;
	isAdmin: boolean;
	isEngineer: boolean;
	isRequestor: boolean;
	lastName: string;
	pxds: UserPxDDto[];
}
export interface CTRExcelQuery
{
	id: number;
}
export interface DistributeWorkloadEvenlyCommand
{
	scenarioCTRId: number;
}
export interface FteDistributionForMatrix
{
	index: number;
	ftePeriods: FtePeriod[];
}
export interface FtePeriod
{
	index: number;
	fteInPeriod: number;
	isPeriodEditable: boolean;
}
export interface GetIsAnyRoleWorkloadEditedCommand
{
	scenarioCtrId: number;
}
export interface HoursDistributionForMatrix
{
	index: number;
	hoursPeriods: HoursPeriod[];
}
export interface HoursPeriod
{
	index: number;
	hoursInPeriod: number;
	isPeriodEditable: boolean;
}
export interface SaveWorkloadDistributionForRolesCommand
{
	scenarioCTRId: number;
	entityId: number;
	engineeringRoleId: number;
	fteDistribution: FteDistributionForMatrix[];
}
export interface SaveWorkloadDistributionForRolesResult
{
	scenarioCTRId: number;
	entityId: number;
	engineeringRoleId: number;
	hours: HoursDistributionForMatrix[];
}
export interface WorkloadForRolesNeedsUpdateQuery
{
	scenarioCtrId: number;
}
export interface DownloadScenarioExportedToExcel
{
	id: number;
}
export interface EngineeringRatesResource
{
	engineeringRoleId: number;
	engineeringRatesId: number;
	engineeringRate: number;
}
export interface RoleResource
{
	engineeringRoleId: number;
	businessSegmentId?: number;
	place: number;
	roleType: string;
	roleName: string;
	defaultPercentage: number;
	isActive: boolean;
	isPending: boolean;
	canBeDeleted: boolean;
}
export interface RolesAndRatesEntityResource
{
	entityId: number;
	entityName: string;
	engineeringRates: EngineeringRatesResource[];
	currencyId: number;
	currencyName: string;
	lastUpdateDate?: string;
}
export interface EntitiesForCollaborateQuery
{
	ctrId: number;
}
export interface EntitiesForCollaborateQueryResult
{
	delegationEntities: EntityForCollaborateDto[];
	allEntities: EntityDto[];
}
export interface EntityDto
{
	entityId: number;
	entityName: string;
}
export interface ErrorDetailsEntity
{
	errorCode: number;
	errorMessage: string;
	stackTrace?: string;
}
export interface GetClientsResponse
{
	clientId: number;
	clientName: string;
	isActive: boolean;
}
export interface GetEngineeringRoleResult
{
	businessSegmentId: number;
	defaultPercentage: number;
	engineeringRoleId: number;
	isActive: boolean;
	place: number;
	roleName: string;
	roleType: EngineeringRoleType;
}
export interface GetRolesAndRatesRequest
{
	businessSegmentId: number;
}
export interface GetRolesAndRatesResult
{
	entityId: number;
	engineeringRates: EngineeringRatesResource[];
	currencyId: number;
	currencyName: string;
}
export interface GetScopeUnitsCommand
{
}
export interface GetSendMessagesCommand
{
}
export interface MailPersonalization
{
	tos: string[];
	bccs: string[];
	templateData: any;
}
export interface TestMessage
{
	subject: string;
	templateId: string;
	personalizations: MailPersonalization[];
}
export interface MediatorViewModel
{
	requestInJson: string;
}
export interface ProjectCurrencyForUpdate
{
	isDefaultCurrency: boolean;
	currencyCode: string;
	fxRate: number;
}
export interface UpdateProjectCurrenciesCommand
{
	projectId: number;
	projectCurrencies: ProjectCurrencyForUpdate[];
}
export interface RolesAndRatesResponse
{
	businessSegmentId: number;
	businessSegmentName: string;
	roles: RoleResource[];
	entities: RolesAndRatesEntityResource[];
}
export interface SaveDelegationCommentCommand
{
	scenarioCTRId: number;
	id: number;
	comment: string;
}
export interface ScenarioClientExcelQuery
{
	id: number;
}
export interface ScenarioCTRsClientExcelQuery
{
	scenarioCTRIds: number[];
	scenarioId: number;
}
export interface UpdateProjectOwnerCommand
{
	projectId: number;
	entityId: number;
	userId: number;
	makeRequestorOfAllCtr: boolean;
	makeOwnerOfAllScenarios: boolean;
	sendNotification: boolean;
}
export interface UpdateUserCommand
{
	userId: number;
	businessSegmentId: number;
	email: string;
	entityId: number;
	firstName: string;
	isActive: boolean;
	isAdmin: boolean;
	isEngineer: boolean;
	isRequestor: boolean;
	lastName: string;
	pxds: UserPxDDto[];
}
export interface WorkloadForRolesUpdatedData
{
	needsUpdate: boolean;
	scenarioCtrId: number;
}
export interface IMediatorRequest
{
}
export interface IGetBlobMediatorRequest
{
}
