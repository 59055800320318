<span class="label">Entity</span>
<div class="options">
  <mat-checkbox
    *ngFor="let entity of availableEntities$ | async"
    [checked]="selectedEntitiesIds.includes(entity.entityId)"
    (change)="onEntityToggle(entity.entityId, $event.checked)"
    >{{ entity.entityName }}({{
      pxdsByEntity[entity.entityId]?.pxdsCount || 0
    }})</mat-checkbox
  >
</div>
