import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  shareReplay,
  skip,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import { selectAllActiveBusinessSegments } from '@collections/business-segments/store/business-segments.selectors';
import { selectPxDByProductIdDisciplineIdFactory } from '@collections/pxds/store/pxds.selectors';
import { legendItems } from '@common/pxd-grid/pxd-cells-legend/pxd-cells-legend.component';
import { LockService } from '@core/lock/lock.service';
import { PxDIndex } from '@models/pxd';

import { exportMasterDataAction } from '../store/admin-activities.actions';
import { selectMastarDataExporting } from '../store/admin-activities.selectors';

export const selectedBusinessSegmentStorageKey =
  'activities:selectedBusinessSegment';
export const selectedPxdStorageKey = 'activities:selectedPxD';

@Component({
  selector: 'app-masterdata-page',
  templateUrl: './masterdata-page.component.html',
  styleUrls: ['./masterdata-page.component.scss'],
  providers: [LockService],
})
export class MasterdataPageComponent implements OnInit, OnDestroy {
  private readonly componentDestroyed$ = new Subject<void>();
  constructor(private readonly store: Store) {}

  public selectedBusinessSegmentId$ = new BehaviorSubject<number>(
    JSON.parse(localStorage.getItem(selectedBusinessSegmentStorageKey)) || null
  );

  public selectedPxDKey$ = new BehaviorSubject<PxDIndex<number>>(
    JSON.parse(localStorage.getItem(selectedPxdStorageKey)) || null
  );

  public selectedPxD$ = this.selectedPxDKey$.pipe(
    switchMap((pxdIndex) =>
      pxdIndex
        ? this.store.select(
            selectPxDByProductIdDisciplineIdFactory(
              pxdIndex.productId,
              pxdIndex.disciplineId
            )
          )
        : of(null)
    ),
    distinctUntilChanged(),
    shareReplay(1)
  );

  public legendItems = [
    legendItems.WITH_MASTERDATA_AND_PRINCIPALS,
    legendItems.WITH_MASTERDATA_WITHOUT_PRINCIPALS,
    legendItems.WITHOUT_MASTERDATA_AND_PRINCIPALS,
    legendItems.WITHOUT_MASTERDATA_WITH_PRINCIPALS,
    legendItems.INACTIVE,
    legendItems.SELECTED,
  ];

  public mastardataIsExporting$ = this.store.select(selectMastarDataExporting);

  public ngOnInit(): void {
    this.initSelectedBussinesSegment();
    this.initSelectedPxD();
  }

  private initSelectedBussinesSegment() {
    this.store
      .select(selectAllActiveBusinessSegments)
      .pipe(
        filter(({ length }) => length > 0),
        take(1),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((segments) => {
        const businessSegmentId = segments[0].businessSegmentId;
        this.selectedBusinessSegmentId$.next(businessSegmentId);
        localStorage.setItem(
          selectedBusinessSegmentStorageKey,
          JSON.stringify(businessSegmentId)
        );
      });
  }

  private initSelectedPxD() {
    this.selectedBusinessSegmentId$
      .pipe(
        distinctUntilChanged(),
        skip(1),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.selectedPxDKey$.next(null);
      });
    this.selectedPxDKey$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((selectedPxD) => {
        localStorage.setItem(
          selectedPxdStorageKey,
          JSON.stringify(selectedPxD)
        );
      });
  }

  public exportMasterdata() {
    this.store.dispatch(
      exportMasterDataAction({
        context: 'MasterdataPageComponent::exportMasterdata',
      })
    );
  }
  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
